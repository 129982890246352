import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { IsConnected, generale_response, global_response } from '../utils/auth';
import { Execution, json_log, scrapedElement } from "../utils/execution";
import axios from "axios";
import { Notification } from "./Notification";
import { axiosInstance } from "../utils/axiosConfig";


export function Dashboard()
{
    const [ loading, setLoading ] = useState( false );
    const navigate = useNavigate();
    const [ history, setHistory ] = useState<Execution[]>([]);
    const [ errorTest, setErrorTest ] = useState<string>("");
    const [ hidden, setHidden ] = useState<boolean>( true );
    const [ email, setEmail ] = useState<string>("");
    const [ active, setActive ] = useState<boolean>( false );
    
    const start_test = async () =>
    {

        setLoading( true );
        setErrorTest("");

        try
        {
            let response = await axiosInstance.get("/watchdog");
            let res = response.data as global_response;
            if( !res.status )
            {
                setErrorTest( res.message || "Something went wrong.");
            }else
            {
                setErrorTest("");
                load_log();
            }


        }catch( e )
        {
            console.log( e );
            setErrorTest("Something went wrong.");
        }

        load_log();
        setLoading( false );
    
    };

    const load_log = async () =>
        {

            interface logList extends global_response
            {
                log: Execution[];
            }
    
            try 
            {
                setErrorTest("");
                setLoading( true );
    
                let req = await axios.get("/api/v1/log"); 
                let res = req.data as logList;
                
                setLoading( false );
                if( !req.status )
                {
                    
                    setErrorTest( res.message || "Failed to load the log" );
                    return;
    
                }else
                {
                    let tmp: Execution[] = [];
                    
                    for( let item of res.log )
                    {
                        item.parsed_log = JSON.parse( item.result ) as json_log;
                        item.result = "";
                        let tmpd = item.date.split(" ");
                        let dx = tmpd[0].split("-");
                        let hx = tmpd[1].split(":");
                        let d = new Date( parseInt(dx[2]), parseInt(dx[1]), parseInt(dx[1]) );
                        d.setHours( parseInt(hx[0]), parseInt(hx[1]) );
                        console.log("DB", d );
                        const gmt3 = new Date( d.getTime() + (3 * 60 * 60 * 1000) );
                        console.log("GMT+3", gmt3 );
                        item.date = gmt3.toLocaleDateString("Us-en", { hour:"2-digit", minute:"2-digit", hourCycle:"h23", month:"long", day:"2-digit", year:"numeric" });
                        console.log("item.date", item.date );
                        item.dump = [];
                        tmp.push( item );
                    }
    
                    setHistory( tmp );
                }
    
            } catch (error) 
            {
                console.log( error );
                setErrorTest( "Failed to load the log" );
                return;
            }
    };

    const logout = async () =>
    {
        let x = await axiosInstance.get("/logout");
        navigate("/login");
    };
   
    useEffect( () => 
    {
        const check = async () =>
        {
            let status = await IsConnected();
            if( status == false )
            {
                console.log("Not connected");
                navigate("/login");
            }
        };
        
        check();
        load_log();
        load_email();

    }, []);

    const change_str = async ( newval: scrapedElement[], index: number ) =>
    {
        const newH = await history.map( (item, i) => { 
            if( i === index )
            {
                item.dump = newval;
                return item;
            }else
            {
                return item;
            }
        });

        setHistory( newH );
    }

    const load_email = async () => 
    {
        interface email_response extends generale_response
        {
            email: string;
            enable: string;
        }
        
        try
        {
            const req = await axiosInstance.get("/email");
            let res = req.data as email_response;
            if( res.status )
            {
                setEmail( res.email );
                setActive( res.enable == "true" );
            }

        }catch
        {
            return false;
        }

    };
    
    const toggle_setting = () => 
    {
        setHidden( !hidden );  
        load_email(); 
    };

    return (
        <>
            <div className="text-lg text-center h-[96vh] overflow-y-scroll bg-slate-900">

                { !hidden ? ( <Notification callback={toggle_setting} email={email} enable={ active==true ? "true":"false"} ></Notification>):'' }
                
                <div className="lg:w-[70vw] w-[99vw] mx-auto py-2 bg-white inline-block px-4 lg:mt-4 shadow">
                    
                    <div>
                        <span className="text-2xl font-bold float-left text-orange-800">
                            History
                        </span>

                        <button className="float-left mt-1 mx-3" onClick={ toggle_setting }>
                            <svg className="h-6 w-6 text-slate-700"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                            </svg>
                        </button>

                    </div>

                    <div className="float-right py-1 ml-5 mr-2 cursor-pointer">
                        <button onClick={ logout }>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 rotate-180">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
                            </svg>
                        </button>
                    </div>

                    <div className="float-right">
                        {/* onClick={start_test} */}
                        <button  className=" py-1 px-5 rounded  shadow-sm font-sans font-bold bg-white text-orange-500">
                            { loading ? <div className="ld ld-ring ld-spin"></div> : "" }
                        </button>
                    </div>
                    
                </div>

                { (errorTest as any).length > 0 ? (
                <div className="w-[70vw] mx-auto py-2 bg-red-100 inline-block px-4 mb-3 animate-bounce ">
                    <span className="text-red-600">
                        { errorTest }
                    </span>
                </div> ) : "" }
                

                <ul className="lg:w-[70vw] w-[95vw]  mx-auto rounded-sm text-left mb-4">
                    { history.map( (item, index) => (
                        
                        <li className="shadow pl-3 mt-3 cursor-pointer text-gray-800 rounded " key={index}>
                            
                            <span className="font-bold w-full bg-white py-3 px-2 block rounded">
                                { item.date }
                                
                                <button onClick={ ()=> (  change_str( item.parsed_log.all_prod, index ) ) } className="mx-2 px-2 text-sm text-white rounded bg-yellow-600"> All </button>
                                <button onClick={ ()=> (  change_str( item.parsed_log.dispo , index ) ) } className="mx-2 px-2 text-sm text-white rounded bg-yellow-600"> Disponible </button>
                                <button onClick={ ()=> (  change_str( item.parsed_log.sd1 , index ) ) } className="mx-2 px-2 text-sm text-white rounded bg-yellow-600"> SD1 </button>
                                { item.dump.length > 0 ?  (
                                    <button onClick={ ()=> (  change_str( [] , index ) ) } className="mx-2 px-2 text-sm text-white rounded bg-red-600"> close </button>
                                ) : "" }
                                
                                
                            </span>

                            <ul className="w-[95%] ml-[5%]">
                                { item.dump.map( (element) => 
                                    <li className="shadow px-2 mt-3 py-3 bg-white rounded " key={element.name}>
                                        <div className="w-full">
                                            {element.name}
                                            <a target="_blank" href={element.url} className="ml-3 text-blue-600"> visit </a>
                                        </div>
                                        { element.availability.split(",")[0].toLocaleLowerCase() == "rupture de stock" ? (
                                            <div className="text-red-500">{element.availability.split(",")[0]}</div>
                                        ) : (
                                            <div className="text-green-500">{element.availability.split(",")[0]}</div>
                                        ) }
                                    </li>
                                )}
                            </ul>


                        </li>

                    )) }               
                </ul>


            </div>

            <div className="w-full text-center text-xs h-[4vh] bg-slate-900 text-white pb-3">
                2024 
                <a href="https://github.com/mrxoder" className="text-blue-300 mx-2">
                    @xoder
                </a>
            </div>

        </>
    );
}
