import axios, { Axios } from "axios";
import { axiosInstance } from "./axiosConfig";

export interface global_response
{
    status: boolean;
    message: string;
}

export async function  IsConnected()
{
    try
    {

        const req = await axiosInstance.get("/user");
        let res = req.data as global_response;

        return res.status;

    }catch( error )
    {
        return false;
    }
}

export  async function Authentification( username: string, password: string)
{
    const req = await axiosInstance.post("/login", { username:username, password: password } );
    let res = req.data as global_response;
    return res;
}

export  async function register( username: string, password: string, invitation: string )
{
    const req = await axiosInstance.post("/signup", { username:username, password: password, invitation: invitation } );
    let res = req.data as global_response;
    return res;
}


export interface generale_response
{
    status: boolean;
    message: string;
}