import { useEffect, useState } from "react"
import axios from 'axios';
import { axiosInstance } from "../utils/axiosConfig";
import { Execution } from '../utils/execution';
import { generale_response } from "../utils/auth";

interface propN
{
    email: string;
    enable: string;
    callback: () => void;
}

export function Notification( props: propN )
{
    const [ email, setEmail ] = useState<string>( props.email );
    const [ password, setPassword ] = useState<string>();
    const [ active, setActive ] = useState<boolean>( props.enable == "true" );
    const [ message , setMessage] = useState<string>();
    
    const set_email = ( e: React.ChangeEvent<HTMLInputElement> ) =>
    {
        setEmail( e.target.value );
    };

    const set_password = ( e: React.ChangeEvent<HTMLInputElement> ) =>
    {
        setPassword( e.target.value );
    };

    const set_activer = ( e: React.ChangeEvent<HTMLInputElement>  ) =>
    {
        setActive( !active  );
    };

    

    const submit = async () => 
    {
        setMessage("");
        if( email == "" )
        {
            setMessage("Invalid email");
            return false;
        }
        if( password == "" )
        {
            setMessage("Invalid password");
            return false;
        }

        const data = {
            email: email,
            password: password,
            enable: active ? "true":"false"
        };

        try
        {
            const req = await axiosInstance.put("/email", data );
            let res = req.data as generale_response;

            if( res.status == false )
            {
                setMessage( res.message || "Impossible de modifier.");
                return;
            }
            props.callback();

        }catch( e )
        {
            setMessage( "Impossible de modifier." );
        }
        
        
    };


    return (

        <div className="w-full flex justify-center absolute top-8 ">

            <div className="lg:w-[60%] w-full text-left px-3 py-3 bg-white mt-3 rounded">
                
                <label className="font-bold my-2"> Notification par Email 
                <span className="font-normal text-red-500 ml-3 text-md"> { message }</span>
                </label>
                
                <div className="w-full ml-2">
                    
                    <div className="my-1">
                        <label> Adresse email </label>
                    </div>
                    <input type="email" value={email} placeholder="email" className="px-2 py-2 w-[95%] bg-orange-100 rounded" onInput={ set_email }/>

                </div>

                <div className="w-full ml-2 mt-4">
                    <input type="checkbox" checked={active} onChange={ set_activer } value="off" />
                    <label className="ml-2"> activé </label>
                </div>

                <div className="my-2 ml-2 mt-2">
                    <div className="my-1">
                        <label> Mot de Passe </label>
                    </div>
                    <input type="password" onInput={ set_password } placeholder="Confirmation de mot de passe" className=" w-[95%] px-2 py-2 rounded bg-orange-100 "/>
                </div>
                
                <div className="mt-2 float-right">
                    <button className=" py-2 px-3 bg-gray-400 mx-3 text-white rounded " onClick={ props.callback }>
                        Annuler
                    </button>
                    <button className=" py-2 px-3 bg-orange-700 text-white rounded " onClick={ submit }>
                        Valider
                    </button>
                </div>

            </div>
        </div>
    );
}