import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { Dashboard } from './Components/Dashboard';
import { Login } from './Components/Login';
import { SignUp } from './Components/SignUp';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


function App()
{

  return (
    <HashRouter>
      <Routes>
          <Route path='/' element={ <Dashboard /> } />
          <Route path='/dashboard' element={ <Dashboard /> } />
          <Route path='/login' element={ <Login /> } />
          <Route path='/register' element={ <SignUp /> } />
      </Routes>
    </HashRouter>
  )
}



root.render(

  <React.StrictMode>
    <App />
  </React.StrictMode>
  
);


reportWebVitals();
