import { useEffect, useState } from "react";
import { Authentification, IsConnected, global_response } from "../utils/auth";
import "./css/login.css";
import { useNavigate } from "react-router-dom";

export function Login()
{
    const [ logged, setLogged ] = useState( false );
    const [ username, setUsername ] = useState( "" );
    const [ password, setPassword ] = useState( "" );
    const [ loginError, setLoginError ] = useState( "" );
    const [ loading, setLoading ] = useState( false );
    
    const navigate = useNavigate();

    const check = async function()
    {
        let status = await IsConnected();
        setLogged( status  );
    };

    check();

    useEffect( () =>
    {
        if( logged == true )
        {
            console.log("connected");
            navigate("/dashboard");
        }

    }, [logged] );


    const input_username = ( element: React.ChangeEvent<HTMLInputElement> ) =>
    {
        setUsername( element.target.value );
    }

    const input_password = ( element: React.ChangeEvent<HTMLInputElement> ) =>
    {
        setPassword( element.target.value );
    };

    const startAuth = async ( element: React.FormEvent<HTMLFormElement> ) =>
    {
        element.preventDefault();
        if( username == "" || password == "" )
        {
            console.log(">>ERROR", username );
            return;
        }

        setLoginError( "" );
        setLoading( true );

        let auth: global_response = {} as global_response;
        
        try 
        {
            auth = await Authentification( username, password );
            if( auth.status )
            {
                navigate("/dashboard");
                return;
            }
            setLoginError( auth.message );  

        } catch (error) 
        {
            setLoginError( "Invalid username or password" );
        }
        
        setLoading( false );
        
    };

    return (

        <div className="text-center flex justify-center bg-slate-100 h-[100vh]">

                <form className="text-center font-mono w-[50%]" id="login_composer" onSubmit={ startAuth }>
                    
                    <div className="mb-[2%]">
                        
                        <div className="flex justify-center w-full mt-2">
                            <img width="64" src="assets/logo.png" />
                        </div>

                        <div className="flex justify-center w-full mt-2 text-red-500">
                            { loginError ? loginError : "" }
                        </div>

                    </div>
                    
                    <div>
                        <input type="text" onInput={ input_username } className="w-[80%] my-2  py-3 shadow pl-3 rounded-[4px] border-orange-200 shadow-orange-200 border" name="username" placeholder="username" autoComplete="off"/>
                    </div>

                    <div>
                        <input type="password" onInput={ input_password } className=" w-[80%] my-2  py-3 pl-3 rounded-[4px] shadow border-orange-200 border" name="password" placeholder="password"  autoComplete="off"/>
                    </div>
                    
                    
                    <button className=" w-[50%] py-3 px-3 border-0 submit shadow" type="submit"> 
                       { loading ? ( <span className="ld ld-spin ld-ring"></span> ) : "Login"}
                    </button>
                    

                </form>

        </div>
    );
}