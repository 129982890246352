import { useEffect, useState } from "react";
import { IsConnected, register } from "../utils/auth";
import "./css/login.css";
import { useNavigate } from "react-router-dom";

export function SignUp()
{
    const [ logged, setLogged ] = useState( false );
    const [ username, setUsername ] = useState( "" );
    const [ password, setPassword ] = useState( "" );
    const [ registerError, setRegisterError ] = useState( "" );
    const [ loading, setLoading ] = useState( false );
    const [ invitation, setInvitation ] = useState( "" );
    
    const navigate = useNavigate();

    const check = async function()
    {
        let status = await IsConnected();
        setLogged( status  );
    };

    check();
    useEffect( () =>
    {
        
        if( logged == true )
        {
            console.log("connected");
            navigate("/dashboard");
        }

    }, [ logged ] );


    const input_username = ( element: React.ChangeEvent<HTMLInputElement> ) =>
    {
        setUsername( element.target.value );
    }

    const input_invitation = ( element: React.ChangeEvent<HTMLInputElement> ) =>
    {
        setInvitation( element.target.value );
    }

    const input_password = ( element: React.ChangeEvent<HTMLInputElement> ) =>
    {
        setPassword( element.target.value );
    };

    const startAuth = async ( element: React.FormEvent<HTMLFormElement> ) =>
    {
        element.preventDefault();
        if( username == "" || password == "" )
        {
            console.log(">>ERROR", username );
            return;
        }

        setRegisterError("");
        setLoading( true );
        let auth = await register( username, password, invitation);
        setLoading( false );
        if( auth.status )
        {
            navigate("/login");
            return;
        }

        setRegisterError( auth.message || "Something went wrong.");
    };

    return (
        <div className="text-center flex justify-center h-[100vh] bg-slate-100">

                <form className="text-center font-mono w-[50%]" id="login_composer" onSubmit={ startAuth }>
                    
                    <div className="mb-[2%]">
                        <div className="flex justify-center w-full mt-2">
                            <img width="64" src="assets/logo.png" />
                        </div>
                    </div>

                    <div className="flex justify-center w-full mt-2 text-red-500">
                        { registerError ? registerError : "" }
                    </div>
                    
                    <div>
                        <input type="text" onInput={ input_username } className="w-[80%] my-2  py-3 shadow-slate-300 shadow pl-3 rounded-[4px]" name="username" placeholder="username" autoComplete="off"/>
                    </div>

                    <div>
                        <input type="password" onInput={ input_password } className=" w-[80%] my-2  py-3 pl-3 rounded-[4px] shadow-slate-300 shadow" name="password" placeholder="password"  autoComplete="off"/>
                    </div>

                    <div>
                        <input type="text" onInput={ input_invitation } className=" w-[80%] my-2  py-3 pl-3 rounded-[4px] shadow-slate-300 shadow" name="password" placeholder="invitation code"  autoComplete="off"/>
                    </div>
                    
                    
                    <button className=" w-[50%] py-3 px-3 border-0 submit shadow" type="submit"> 
                       { loading ? ( <span className="ld ld-spin ld-ring"></span> ) : "Sign Up"}
                    </button>
                    

                </form>

        </div>
    );
}